const processSatus = require('./processSatus')

export function StoreProcess(data){

    $.ajax({
        url: "/ilicits/process/store/",
        contentType: 'json',
        type: 'GET',
        data: data,
        success: (success) => {
            var editor = tinymce.get('commentEditorModal');
            editor.setContent('');
            const table = $('#datatable-auto-process').DataTable();
            table.ajax.reload();

            const billing = $('#inputBillingStatus option:selected').text()

            //GET AND DISABLE FORMS
            let invoiceForm =  document.getElementById('invoiceForm').style.display
            let communicationUser = document.getElementById('communicationUser').style.display
            communicationUser == 'block' ? communicationUser = 'none' : ''

            if(data.action == 'Concluir' || billing == 'Pago na totalidade' || data.invoiceNumber != '' || billing == 'Pago por fracção em acordo'){
                invoiceForm == 'block' ? communicationUser = 'none' : ''
                location.reload();
            }

        },
            error: function(xhr){
          //  console.log(xhr);
        }
    })
}




/**
 *CONCLUSION OF PROCESS
 *
 */
export function Conclude(){

    const currentstep = document.querySelector('.conclude').id
    const occurrenceID = document.getElementById('occurrenceID').value

    document.getElementById('validateProcessTitle').style.display = 'none'
    document.getElementById('validateWarning').style.display = 'none'
    document.getElementById('backtrackProcessTitle').style.display = 'none'
    document.getElementById('backtrackWarning').style.display = 'none'

    document.getElementById('concludeProcessTitle').style.display = 'block'
    document.getElementById('concludeWarning').style.display = 'block'

    document.getElementById('btnBacktrack').style.display = 'none'
    document.getElementById('btnValidate').style.display = 'none'
    document.getElementById('btnCancel').style.display = 'none'
    document.getElementById('btnConclude').style.display = 'block'

    const data = {'occurrenceID': occurrenceID, 'currentstep': currentstep, 'action': 'Concluir'}
    return data;
}



/**
 * Forward process to the next Step
 * @returns data
 */
export function Foward(){

    const currentstep = document.querySelector('.currentstep').id
    const occurrenceID = document.getElementById('occurrenceID').value

    document.getElementById('validateProcessTitle').style.display = 'block'
    document.getElementById('validateWarning').style.display = 'block'
    document.getElementById('backtrackProcessTitle').style.display = 'none'
    document.getElementById('backtrackWarning').style.display = 'none'
    document.getElementById('concludeProcessTitle').style.display = 'none'
    document.getElementById('concludeWarning').style.display = 'none'

    document.getElementById('btnBacktrack').style.display = 'none'
    document.getElementById('btnValidate').style.display = 'block'
    document.getElementById('btnCancel').style.display = 'none'
    document.getElementById('btnConclude').style.display = 'none'

    if(document.getElementById("actionToTaken").disabled){
        document.getElementById("actionToTaken").removeAttribute("disabled");
        const actionsToTaken = ['Aprovar', 'Atualizar', 'Validar'];
        let option = `<option value="" Selected>Selecionar</option>`
        actionsToTaken.forEach( item => {
            option += `<option value="${item}">${item}</option>`
        })

        $('#actionToTaken').html(option)
    }


    processSatus.hendleStatus(currentstep);
    const nextstep = Number(currentstep) + 1

    const data = {'nextstep' : nextstep, 'occurrenceID': occurrenceID, 'currentstep': currentstep}

    return data;
}




/**
 *
 * @returns backtrackData
 */
export function Backtrack(){
    const currentstep = document.querySelector('.backtrack').id
    const occurrenceID = document.getElementById('occurrenceID').value

    document.getElementById('validateProcessTitle').style.display = 'none'
    document.getElementById('validateWarning').style.display = 'none'
    document.getElementById('backtrackProcessTitle').style.display = 'block'
    document.getElementById('backtrackWarning').style.display = 'block'
    document.getElementById('concludeProcessTitle').style.display = 'none'
    document.getElementById('concludeWarning').style.display = 'none'

    document.getElementById('btnBacktrack').style.display = 'block'
    document.getElementById('btnValidate').style.display = 'none'
    document.getElementById('btnCancel').style.display = 'none'
    document.getElementById('btnConclude').style.display = 'none'

    const previousStep = Number(currentstep) - 1

    $('#actionToTaken').html(`<option value="Recuar" Selected>Recuar</option>`)
    document.getElementById("actionToTaken").setAttribute("disabled", "disabled");

    const backtrackData = {'previousStep' : previousStep, 'occurrenceID': occurrenceID, 'currentstep': currentstep}

    return backtrackData;
}

$( () => {
    if($('#carLocation').length > 0){
        $('#showLessCarLocation').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessCarLocation').style.display = 'none';
                document.getElementById('showMoreCarLocation').style.display = 'block';
                document.getElementById('contentCarLocation').style.display = 'none';
            }, 100)
        })

        $('#showMoreCarLocation').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessCarLocation').style.display = 'block';
                document.getElementById('showMoreCarLocation').style.display = 'none';
                document.getElementById('contentCarLocation').style.display = 'block';
            }, 100)
        })


        if($('#historic').length > 0){
            if($('#showLocationHistory').is(':checked')){
                setTimeout( () => {
                    document.getElementById('locationHistoryContent').style.display = 'block';
                }, 100)
            }
        }

        $('#showLocationHistory').on('change', (event) => {
            event.preventDefault()
            if($('#showLocationHistory').is(':checked')){
                document.getElementById('locationHistoryContent').style.display = 'block';
            } else{
                document.getElementById('locationHistoryContent').style.display = 'none';
            }
        })



        $('#showChangeLocationModal').on('click', (event) => {
            event.preventDefault()

            $('#changeLocationModal').modal('show')
            const delegationID = document.getElementById('locationDelegation').value;

            $.ajax({
                url: `/delegations/allDelegations/`,
                data: {vehicleID: 1},
                type: 'GET',
                success:function(data){

                    var options = '<option value "" selected>Selecionar</option>';

                    if(data.length > 0){
                        data.forEach( (item) => {
                            options += `<option value = "${item.id}" ${item.id == delegationID ? 'selected' : ''}>${item.designation}</option>`
                        })
                    }

                    $('#inputDelegation').html(options);
                },
                error: function(xhr){
                    //console.log(xhr);
                }
            })


            if(delegationID){
                $.ajax({
                    url: `/delegations/costCenters/${delegationID}`,
                    type: 'GET',
                    success:function(data){
                        const costCenterID = document.getElementById('locationCostCenter').value;
                        var options = '<option value="" selected> Selecionar</option>'
                        if(data.length > 0){
                            data.forEach( (item) => {
                                options += `<option value="${item.id}" ${item.id == costCenterID ? 'selected' : ''}>${item.cost_center_designation}</option>`
                            })

                        }
                        $('#inputCostCenter').html(options);
                    },
                    error: function(xhr){
                        console.log(xhr);
                    }
                })
            }

            $.ajax({
                url: `/fleets/vehicleDriver/getAllDrivers/`,
                type: 'GET',
                success:function(data){
                    const userID = document.getElementById('locationDriver').value;
                    var options = "<option value=''>Selecionar</option>"

                    if(data.length > 0){
                        data.forEach( (item) => {
                            options += `<option value="${item.id}" ${item.id == userID ? 'selected' : ''}>${item.name}</option>`
                        })
                        $('#inputDriver').html(options);
                    }
                },
                error: function(xhr){
                }
            })
        })
    }
})

$( () => {
    if($('#allvehicles').length > 0){

        function generateRandomColors(numColors, alpha) {
            const colors = [];
            for (let i = 0; i < numColors; i++) {
                const r = Math.floor(Math.random() * 256);
                const g = Math.floor(Math.random() * 256);
                const b = Math.floor(Math.random() * 256);
                colors.push(`rgba(${r}, ${g}, ${b}, ${alpha})`);
            }
            return colors;
        }

        $.ajax({
            url: '/fleets/vehicle/totalPerDelegations',
            type:'GET',
            contentType: 'JSON',
            success: function(success){

                var data = [];
                var labels = [];
                var total = 0;
                success[0].forEach((element, idex) => {
                    labels[idex]  = element.designation.substring(0, 10);
                    data[idex] = element.vehicles;
                    total = total + element.vehicles;
                });

                console.log(success[0]);
                
                const numColors = success[3].length;
                const backgroundColor = generateRandomColors(numColors, 0.5);

                document.getElementById('totalVehiclesContract').innerHTML = total
                const ctx = document.getElementById('allvehicles_r');
                const chart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: '',
                            backgroundColor: backgroundColor,
                            borderColor: backgroundColor.alpha = 1,
                            data: data,
                            borderWidth: 1
                        }]
                    },
                    options: {
                        plugins: {
                            datalabels: {
                                display: true,
                                color: 'black',
                                anchor: 'end',
                                align: 'top'
                            }
                        }
                    }
                });
            },
            error: function(xhr){
                console.log(xhr);
            }
        })
    }
})

$( () => {
    if($('#chargesForms').length > 0){
        let aux = 0;

        const chargesID = new Array();
        const arrayForms = new Array();
        const functions = require('./functions');


        $('#inputDescriptionCharges').on('change', (event) => {
            event.preventDefault();
            aux += 1

            const chargeForm = new Array();

            const descriptionID = document.getElementById('inputDescriptionCharges').value;

            $.ajax({
                url:`/budgets/subitem/${descriptionID}`,
                type: 'GET',
                enctype: 'json',
                success: (success) => {
                    if(success){

                        const description = success.subitem_description

                        const template = document.getElementById('chargesTemplate').innerHTML;

                        const iconRemove = `<div class="col-md-12 mb-1 mt-2"><span class="float-right text-danger" title="Remover"><i class="fas fa-minus-circle removeWaterCharges" id="${aux}" style="cursor:pointer"></i></span></div>`

                        const inputDescription = `  <div class="col-md-12 mb-3">
                                                        <input type="text" class="form-control" value="${description}" disabled>
                                                        <input hidden type="number" name="inputWaterChargesID[]" value="${descriptionID}" class="form-control" id= "inputWaterChargesID${aux}">
                                                    </div>
                                                `

                        $('#chargesTitle').innerHTML = inputDescription

                        const idIsIncluded = chargesID.includes(descriptionID);

                        idIsIncluded == false ? chargesID.push(descriptionID) : ''

                        idIsIncluded == true ? chargeForm : chargeForm.push(`<div class="row newWitnessForm mb-3" id="chargeForm${aux}">${iconRemove + inputDescription + template}</div>`)
                        idIsIncluded == true ? arrayForms : arrayForms.push(`<div class="row newWitnessForm mb-3" id="chargeForm${aux}">${iconRemove + inputDescription + template}</div>`)

                        $('#chargesSelected').append(chargeForm)

                        $("#inputDescriptionCharges").val("");

                        const materialsSutotal = document.getElementById('inputMaterialSutotal')
                        const vehiclesSutotal = document.getElementById('inputvehicleSubTotal')
                        const laborsSutotal = document.getElementById('inputLaborSubTotal')
                        const waterLossSutotal = document.getElementById('inputLosesSubTotal')

                        let incidence = 0;

                        if(success.subitem_description == 'Encargos administrativos sobre materiais, viaturas e mão de obra'){
                            incidence = Number(materialsSutotal.value) + Number(vehiclesSutotal.value) + Number(laborsSutotal.value)
                        }

                        if(success.subitem_description == 'IVA sobre materiais, viaturas, mão de obra e encargos administrativos'){
                            const previosChargeValue = $(`#chargeForm${aux-1} .col-md-4 #inputChargeValue`).val()
                            const previosIncidence = $(`#chargeForm${aux-1} .col-md-4 #inputChargeIncidence`).val()
                            const previousResult = Number(previosChargeValue) + Number(previosIncidence)
                            incidence = Number(previousResult) ? Number(previousResult) : 0;
                        }

                        if(success.subitem_description == 'IVA sobre perda de água'){
                            incidence = Number(waterLossSutotal.value)
                        }

                        const getChargeValue = incidence * (success.subitem_amount/100);

                        if(success.subitem_description == 'Encargos judiciais* (sempre que necessária a presença de autoridades)'){
                            $(`#chargeForm${aux} .col-md-4 #inputChargeValue`).val(success.subitem_amount)
                            $(`#chargeForm${aux} .col-md-4 #chargeValue`).val(success.subitem_amount)
                        }else{
                            $(`#chargeForm${aux} .col-md-4 #inputChargeIncidence`).val(incidence.toFixed(2))
                            $(`#chargeForm${aux} .col-md-4 #inputChargePercentage`).val(success.subitem_amount)
                            $(`#chargeForm${aux} .col-md-4 #inputChargeValue`).val(getChargeValue.toFixed(2))

                            $(`#chargeForm${aux} .col-md-4 #chargeIncidence`).val(incidence.toFixed(2))
                            $(`#chargeForm${aux} .col-md-4 #chargePercentage`).val(success.subitem_amount)
                            $(`#chargeForm${aux} .col-md-4 #chargeValue`).val(getChargeValue.toFixed(2))
                        }

                        $(`#chargeForm${aux} .col-md-12 #subitemCharges`).val(Number(success.id))
                        functions.handleWithTotalValues()

                        document.getElementById('NoChargesSelected').style.display = 'none';

                    }
                },
                error: (xhr) => {

                }
            })
        })


        document.addEventListener('click', (event) => {
            if(event.target.matches('.removeWaterCharges')){
                const formItemID = event.target.id
                const descriptionIdToRemove = document.getElementById(`inputWaterChargesID${formItemID}`).value

                var indexToRemove = chargesID.indexOf(descriptionIdToRemove);
                if (indexToRemove !== -1) {
                    chargesID.splice(indexToRemove, 1);
                }

                document.getElementById(`chargeForm${formItemID}`).remove()
                functions.handleWithTotalValues()
            }
        })
    }
})

export function hendleInvoiceForm(){

        const invoiceNumber = document.getElementById('inputInvoiceNumber').value
        const invoiceValue = document.getElementById('inputInvoiceValue').value
        const invoiceDate = document.getElementById('inputInvoiceDate').value
        const invoiceBudget = document.getElementById('inputOccurrenceBudget').value

        var invoiceNumberErrorMessage =  document.getElementById('invoiceNumberErrorMessage');
        var invoiceValueErrorMessage =  document.getElementById('invoiceValueErrorMessage');
        var invoiceDateErrorMessage =  document.getElementById('invoiceDateErrorMessage');

        invoiceNumber == '' ? invoiceNumberErrorMessage.innerHTML = 'Este campo é obrigatório' : invoiceNumberErrorMessage.innerHTML = ''
        invoiceValue == '' ? invoiceValueErrorMessage.innerHTML = 'Este campo é obrigatório' : invoiceValueErrorMessage.innerHTML = ''
        invoiceDate == '' ? invoiceDateErrorMessage.innerHTML = 'Este campo é obrigatório' : invoiceDateErrorMessage.innerHTML = ''

        const data = {
                        'invoiceNumber': invoiceNumber,
                        'invoiceValue': invoiceValue,
                        'invoiceDate':  invoiceDate,
                        'invoiceBudget': invoiceBudget
                    }

        return data
}

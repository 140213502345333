export function showValidationModal(){

    document.getElementById('validateModalTitle').style.display = ''
    document.getElementById('concludeModalTitle').style.display = 'none'
    document.getElementById('backtrackModalTitle').style.display = 'none'

    document.getElementById('checkErrors').style.display = 'none'
    document.getElementById('validate').style.display = 'block'
    document.getElementById('conclude').style.display = 'none'
    document.getElementById('backtrack').style.display = 'none'

    $('#processActionModal').modal('show');
}



export function showBackTrackModal(){

    document.getElementById('validateModalTitle').style.display = 'none'
    document.getElementById('concludeModalTitle').style.display = 'none'
    document.getElementById('backtrackModalTitle').style.display = 'block'

    document.getElementById('validate').style.display = 'none'
    document.getElementById('conclude').style.display = 'none'
    document.getElementById('backtrack').style.display = 'block'

    $('#processActionModal').modal('show');
}


export function showModalConclusion(){

    document.getElementById('validateModalTitle').style.display = 'none'
    document.getElementById('concludeModalTitle').style.display = 'block'
    document.getElementById('backtrackModalTitle').style.display = 'none'

    document.getElementById('validate').style.display = 'none'
    document.getElementById('conclude').style.display = 'block'
    document.getElementById('backtrack').style.display = 'none'

    $('#processActionModal').modal('show');
}


export function closeModal(){
    var editor = tinymce.get('commentEditorModal');
    editor.setContent('');
    const table = $('#datatable-auto-process').DataTable();
    table.ajax.reload();
}



export function hideButtons(){
    const billingStatus = document.getElementById('billingStatus').style.display
    const communicationUser = document.getElementById('communicationUser').style.display
    const invoiceForm = document.getElementById('invoiceForm').style.display

    if( billingStatus == 'block' || communicationUser == 'block' || invoiceForm == 'block')
    {
        document.getElementById('checkErrors').style.display = 'block'
        document.getElementById('validate').style.display = 'none'
    } else{
        document.getElementById('checkErrors').style.display = 'none'
        document.getElementById('validate').style.display = 'block'
    }
    if(data.action == 'Concluir' || data.action == 'Recuar'){
        document.getElementById('validate').style.display = 'none'
    }
}

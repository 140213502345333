$( () => {

    if(($('#vehicleForm').length) > 0){
        const addCard = document.getElementById('addCard')
        const inputRegister = document.getElementById('inputRegister')
        const showFuelcardForm = document.getElementById('showFuelcardForm')
        //const delegation = document.getElementById('inputDelegation');

        $('#inputRegister').on('blur', function(event){
            event.preventDefault()

            const registration = inputRegister.value

            $.ajax({
                url: "/fleets/fuelcardByRegistration",
                data: { registration: registration },
                type: 'GET',
                contentType: 'json',
                success: function(success){

                    var options = '<option value="" selected>Selecionar</option>';

                    if(success.length < 1 && registration.length > 0){

                        addCard.style.display = "block"

                        var options = '<option value="" selected>Nenhum cartão associado a Matrícula</option>';
                        $('#inputFuelcard').prop('disabled', 'disabled');

                        $('#inputRegisterCard').on('change', function(event){
                            event.preventDefault()
                            if(this.checked) {
                                showFuelcardForm.style.display = "block"
                            }

                             if(!this.checked) {
                                showFuelcardForm.style.display = "none"
                            }
                        })

                    } else{

                        if(success[0].assignment == 1){

                            addCard.style.display = "none"
                            var options = '<option value="" selected>O Cartão já está atribuido</option>';
                            $('#inputFuelcard').prop('disabled', 'disabled');

                        } else{

                            addCard.style.display = "none"
                            showFuelcardForm.style.display = "none"
                            $('#inputFuelcard').removeAttr("disabled");
                        }
                    }

                    success.forEach(function(item) {
                        options += '<option value="' + item.id + '">' + item.fuel_Card_Number + '</option>';
                    });

                    $('#inputFuelcard').html(options);

                },

                error: function(xhr){
                    console.log(xhr);
                }
            })
        })


    /*     $("#inputDelegation").on('change', function(event){
            event.preventDefault()
            $.ajax({
                url: `/delegations/allDelegations/${delegation.value}`,
                type: 'GET',
                success:function(data){
                    var options = '<option value "" selected> Selecionar</option>'

                    if(data.length < 1){
                        $('#inputFleet').prop('disabled', 'disabled');
                    } else{
                        data.forEach( (item) => {

                            options += '<option value="' + item.id + '">' + item.name + '</option>';
                        })
                    }


                    $('#inputFleet').html(options);
                },
                error: function(xhr){
                    console.log(xhr);
                }
            })
        }) */


        $("#inputContractInfo").on('change', function(event){
            if($("#inputContractInfo")[0].checked){
                document.getElementById('contractInputs').style.display = "block"
                document.getElementById("contractInfo").checked = true;
            } else {
                document.getElementById('contractInputs').style.display = "none"
                document.getElementById("contractInfo").checked = false;
            }
        })


        $("#inputVehicleInsurance").on('change', function(event){
            if($("#inputVehicleInsurance")[0].checked){
                document.getElementById('vehicleInsuranceInputs').style.display = "block"
                document.getElementById("inputVehicleInsuranceInfo").checked = true;
            } else {
                document.getElementById('vehicleInsuranceInputs').style.display = "none"
                document.getElementById("inputVehicleInsuranceInfo").checked = false;
            }
        })


        $("#inputLandTransport").on('change', function(event){
            if($("#inputLandTransport")[0].checked){
                document.getElementById('landTransportInputs').style.display = "block"
                document.getElementById("inputLandTransportInfo").checked = true;
            } else {
                document.getElementById('landTransportInputs').style.display = "none"
                document.getElementById("inputLandTransportInfo").checked = false;
            }
        })
    }



    if(($('#contractForm').length || $('#ItemsForm').length) > 0){

        const delegation = document.getElementById('inputDelegation');

        $("#inputDelegation").on('change', function(event){
            event.preventDefault()

            $('#contractForm').length  > 0 ? document.getElementById('inputDesignations').value = delegation.options[delegation.selectedIndex].innerHTML : ''

            $.ajax({
                url: `/fleets/vehicle/delegationVehicles/${delegation.value}`,
                type: 'GET',
                success:function(data){
                    var options = "<option selected value=''>Selecionar</option>"

                    if(data.length < 1){
                        /* options = '<option selected> Sem Veículos para o Contrato</option>'; */
                      /*   $('#inputVehicle').prop('disabled', 'disabled'); */
                    } else{
                        data.forEach( (item) => {
                            options += '<option value="' + item.id + '">' + item.vehicle_registration + '</option>';
                        })
                    }

                    $('#inputVehicle').html(options);
                },
                error: function(xhr){
                    console.log(xhr);
                }
            })
        })
    }



    if($('#fomScheduleReturn').length > 0){

        $("#shedule").on('click', function(event){
            event.preventDefault()

            var inputID = document.getElementById('inputID').value
            var inputScheduleDate = document.getElementById('inputScheduleDate').value
            var registration = document.getElementById('inputRegistration').value
            const alertContent =  document.getElementById('alertContent')
            const btnClose =  document.getElementById('btnClose')

            datetime = inputScheduleDate.replace("T", " as ");


            $.ajax({
                url: `/fleets/vehicle/getvehicleByID/${inputID}`,
                type: 'GET',
                success:function(data){

                    const Okay = data.length > 0 ?  true : null

                    if(Okay){
                        if(data[0].gps_removed == 0 && inputScheduleDate){


                            $.ajax({
                                url: '/fleets/vehicle/updateSchedulereturnDate',
                                type: 'GET',
                                data: { id: inputID, inputScheduleDate: inputScheduleDate },
                                contentType: 'json',
                                success(success){

                                    $('#alertContent').html(`<strong><i class="fas fa-check-circle"></i> Agendamento para devolução efutuada com sucesso!</strong>`)

                                    if(data[0].gps == 1 ){
                                        var alertcontent = `O Veículo com a matrícula <strong> ${ registration } </strong> tem dispositivo de geolocalização instalado. Tem de ser removido antes da devolução marcada para <strong>${ datetime }</strong>.`
                                        $('#contentBody').html(alertcontent)
                                    }

                                    $("#alertGPDModal").modal("show");
                                    inputScheduleDate = '' ;

                                    $("#alertGPDModal").on('click', function(event){
                                        event.preventDefault()
                                        window.location.replace("/fleets/vehicle");
                                    })

                                },

                                error: function(xhr){
                                    if(alertContent.classList.contains('alert-success'))
                                        alertContent.classList.toggle('alert-danger')
                                    $('#alertContent').html(`<strong><i class="fas fa-times-circle"></i> O Agendamento não foi efetuado. Verifique as iformações que inseriu.</strong>`)
                                    $("#alertGPDModal").modal("show");
                                }
                            })
                        }
                    }
                },
                error: function(xhr){
                    //
                }
            })

        })

    }




    $("#inputTeam").on('change', function(event){
        event.preventDefault()

        const team = document.getElementById('inputTeam');
        console.log('Passa');
        $.ajax({
            url: `/fleets/vehicle/vehicleTeam/${team.value}`,
            type: 'GET',
            success:function(data){
                var options = "<option selected value=''>Selecionar</option>"

                if(data.length < 1){
                    options = '<option selected>Sem Veículos</option>';
                    $('#inputFleet').prop('disabled', 'disabled');
                } else{
                    $('#inputFleet').removeAttr("disabled");
                }

                const notApplyed = {id: 'Não aplicável', vehicle_registration: "Não aplicável"}
                data.push(notApplyed)

                console.log(data);

                data.forEach( (item) => {
                    options += '<option value="' + item.id + '">' + item.vehicle_registration + '</option>';
                })

                $('#inputVehicle').html(options);
            },
            error: function(xhr){
                //console.log(xhr);
            }
        })
    });

    $(document).ready(function($) {
        $(".clickable-row").click(function() {
            window.location = $(this).data("href");
        });
    });
   /*  $('#multiple-select').mobiscroll().select({
        inputElement: document.getElementById('my-input'),
        touchUi: false
    }); */

})



$( () => {

    //const processSatus = require('./modules/process/processSatus')
    const processComments = require('./modules/process/comments')
    const processModals = require('./modules/process/processModals')
    const process = require('./modules/process/process')
    const invoiceProcess = require('./modules/process/inovices')

    //DESABLE BUTTON TO SUBMIT FORM IN MODAL FOR VALIDATE/BACKTRACKING/CONCLUE PROCESS TO VALIDATE ENTRY DATA
    $('#processActionModal').on('show.bs.modal',  () => {
        processModals.hideButtons()
    })


    if(('#icilitprocess').length > 0){

        const formData = new FormData()
        const object = {   stepChaged: '',
                            occurrenceID: '',
                            previousStatus: '',
                            action: '',
                            comment: '',
                            inputFiles: [],
                            inputPictures: [],
                            inputFormCommunication: '',
                            inputCommunicationReference: '',
                            inputCommunicationPhone: '',
                            inputCommunicationDate: '',
                            inputCommunicationLocal: '',
                            invoiceNumber: '',
                            invoiceValue: '',
                            invoiceDate: '',
                            invoiceBudget: '',
                            settlementDate: '',
                            amountFractioned: '',
                            paymentDate: '',
                            paymentDescription: '',
                            paymentOption: ''
                        }



        //Validate Processo
        $('#icilitprocess').on('click', '.currentstep', (event) => {
            event.preventDefault()

            const validationData = process.Foward();

            data = object;
            data.stepChaged = validationData.nextstep
            data.occurrenceID = validationData.occurrenceID
            data.previousStatus = validationData.currentstep
        })


        //Conclude Processo
        $('#icilitprocess').on('click', '.conclude', (event) => {
            event.preventDefault()
            const dataConclusion = process.Conclude()

            data = object;
            data.stepChaged = dataConclusion.stepChaged
            data.occurrenceID = dataConclusion.occurrenceID
            data.previousStatus = dataConclusion.currentstep
            data.action = dataConclusion.action

            $.ajax({
                url: `/ilicits/process/getStatusByName/`,
                type: 'GET',
                data: {designation: 'Concluído'},
                contentType: 'json',
                success: (success) => {
                    $('#actionToTaken').html(`<option value="Concluir" Selected>Concluir</option>`)
                    document.getElementById("actionToTaken").setAttribute("disabled", "disabled");

                    data.stepChaged = success[0].id
                    $('#actionWarning').modal('show');
                },
                error: function(xhr){
                  //  console.log(xhr);
                }
            })
        })


        //Backtrack Process
        $('#icilitprocess').on('click', '.backtrack', (event) => {
            event.preventDefault()

            const backtracData = process.Backtrack()
            data = object;
            data.action = 'Recuar'
            data.stepChaged = backtracData.previousStep
            data.occurrenceID = backtracData.occurrenceID
            data.previousStatus = backtracData.currentstep

            $('#actionWarning').modal('show');
        })


        $('#btnBacktrack').on('click', (event) => {
            event.preventDefault()
            processModals.showBackTrackModal()
        })


        $('#btnValidate').on('click', (event) => {
            event.preventDefault()
            processModals.showValidationModal();
        })

        $('#btnConclude').on('click', (event) => {
            event.preventDefault()
            processModals.showModalConclusion( );
        })



        $(document).on("click", '#backtrack, #conclude, #validate, #checkErrors', function(event) {

            event.preventDefault()
            const editor = tinymce.get('comment');

            const actionToTaken = document.getElementById('actionToTaken').value;

            const formCommunication = document.getElementById('inputFormCommunication').value
            const communicationReference = document.getElementById('inputCommunicationReference').value
            const communicationLocal = document.getElementById('inputCommunicationLocal').value
            const communicationDate = document.getElementById('inputCommunicationDate').value
            const communicationPhone = document.getElementById('inputCommunicationPhone').value

            data.action = data.action == '' ? actionToTaken : data.action
            data.comment =  editor.getContent()

            formData.append('action', actionToTaken)
            formData.append('comment', editor.getContent() )

            const communicationUser = document.getElementById('communicationUser').style.display
            const billingStatus = document.getElementById('billingStatus').style.display

            if(communicationUser == 'block'){

                data.inputFormCommunication = formCommunication
                data.inputCommunicationReference = communicationReference
                data.inputCommunicationDate = communicationDate
                data.inputCommunicationLocal = communicationLocal
                data.inputCommunicationPhone = communicationPhone
                data.action = 'Atualização';

                if(formCommunication  == ''){
                    const formCommunication = document.getElementById('inputFormCommunication')
                    const formErrorMessage = document.getElementById('formErrorMessage')
                    formCommunication.value == '' ? formErrorMessage.innerHTML = 'Este campo é obrigatório' : formErrorMessage.innerHTML = ''
                }
            }


            if(billingStatus == 'block'){

                if(formCommunication  == ''){
                    const formCommunication = document.getElementById('inputFormCommunication')
                    const formErrorMessage = document.getElementById('formErrorMessage')
                    formCommunication.value == '' ? formErrorMessage.innerHTML = 'Este campo é obrigatório' : formErrorMessage.innerHTML = ''
                }
            }


            if(document.getElementById('invoicepayments').style.display == 'block'){
                data.amountFractioned = document.getElementById('inputAmountFractioned').value
                data.paymentDate = document.getElementById('inputPaymentDate').value
                data.paymentDescription = document.getElementById('inputPaymentDescription').value
            }


            if(document.getElementById('invoiceForm').style.display == 'block'){
                const invoiceData = invoiceProcess.hendleInvoiceForm();
                data.invoiceNumber = invoiceData.invoiceNumber
                data.invoiceValue = invoiceData.invoiceValue
                data.invoiceDate = invoiceData.invoiceDate
                data.invoiceBudget = invoiceData.invoiceBudget
                data.action = 'Atualização'
            }

            process.StoreProcess(data)
        })



        $(document).on("change", '#inputSettlementDate', function(event) {
            event.preventDefault()
            data.settlementDate = document.getElementById('inputSettlementDate').value;
        })


        $(document).on("change", '#inputBillingStatus', function(event) {
            event.preventDefault()

            const currentstep = document.querySelector('.currentstep').id
            const typePayment = $('#inputBillingStatus option:selected').text();
            const invoicePayment = document.getElementById('invoicepayments')

            if(typePayment == 'Pago por fracção em acordo'){
                invoicePayment.style.display = 'block'
                data.stepChaged = Number(currentstep) + 1;
            } else{
                invoicePayment.style.display = 'none'
                data.stepChaged = document.getElementById('inputBillingStatus').value;
            }
        })


        $('#processActionModal').on('hidden.bs.modal', function (e) {
            e.preventDefault();
            document.getElementById('formErrorMessage').innerHTML =''
            document.getElementById('formErrorMessage').innerHTML = ''

        });


        //Handle with closing Modal
        $(document).on("click", '#closeModalComment, #notExecute, #closeModal', function(event) {
            event.preventDefault();
            processModals.closeModal()
        });


        $('#commentModal').on('hidden.bs.modal', function (e) {
            var editor = tinymce.get('commentEditorModal');
            editor.setContent('');
            const table = $('#datatable-auto-process').DataTable();
            table.ajax.reload();
        });


        //Update Comment and Show it in Modal
        $(document).on( "click", '.comment', function( event ) {
            event.preventDefault()
            let process = $( event.target ).closest( "span" ).toggleClass('comment').attr('id');
            processComments.updateComment(process)
        });


        //Add comment in a Process
        $(document).on( "click", '.addcomment', function( event ) {
            event.preventDefault()
            let process = $( event.target ).closest( "span" ).toggleClass('addcomment').attr('id');
            var editor = tinymce.get('commentEditorModal');
            processComments.addComment(process, editor)
        })
    }
})






$(() => {

    window.mce = [];

    if ($('textarea.text-editor').length > 0) {
        let editor_config = {
            path_absolute : "/",
            selector: "textarea.text-editor",
            language: 'pt_PT',
            menubar:false,
            statusbar: false,
            plugins: [
                "advlist autolink lists link charmap print preview hr anchor pagebreak",
                "searchreplace visualblocks visualchars code",
                "insertdatetime nonbreaking table directionality",
                "emoticons template paste textpattern"
            ],
            // media image
            toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent link", // image media",
            relative_urls: false,
            file_browser_callback : function(field_name, url, type, win) {
                var x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
                var y = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight;

                // var cmsURL = editor_config.path_absolute + 'laravel-filemanager?field_name=' + field_name;
                // if (type == 'image') {
                //     cmsURL = cmsURL + "&type=Images";
                // } else {
                //     cmsURL = cmsURL + "&type=Files";
                // }

                // tinyMCE.activeEditor.windowManager.open({
                //     file : cmsURL,
                //     title : 'Filemanager',
                //     width : x * 0.8,
                //     height : y * 0.8,
                //     resizable : "yes",
                //     close_previous : "no"
                // });
            },
            id: $('textarea.text-editor').data('mce-id')
        };

        tinyMCE.init(editor_config).then((mce) => {
            window.mce = mce;
        });



        // $('#formNextStatus').on('sumbit', () => {
        //     $('#inputComment').val(quill.root.innerHTML);
        // });
    }
});

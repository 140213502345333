const occurenceBudget = require('./budget');

const getBillingState = async (param) => {
    $.ajax({
        url: "/ilicits/process/billingstates/",
        contentType: 'json',
        type: 'GET',
        data: {param: param},
        success: (success) => {
            let option = `<option value="" selected>Selecional</option>`
            success.forEach(item => {
                    option += `<option value="${item.id}">${item.occurrence_status_designation}</option>`
                });

            $('#inputBillingStatus').html(option)
               document.getElementById('billingStatus').style.display = 'block'
        },
            error: function(xhr){
          //  console.log(xhr);
        }
    })
}


export function hendleStatus(statusId){

    $.ajax({
        url: `/ilicits/process/status/${statusId}`,
        type: 'GET',
        contentType: 'json',
        success: (success) => {
    
            const status = success[0].occurrence_status_designation;

            if(status == 'Comunicação ao utilizador' || status == 'Aguardar comunicação ao utilizador'){
                document.getElementById('communicationUser').style.display = 'block'
                document.getElementById('invoiceForm').style.display = 'none'

                $('#actionToTaken').html(`<option value="Atualização" Selected>Atualização</option>`)
                document.getElementById("actionToTaken").setAttribute("disabled", "disabled");
            }

            if(status == 'Emissão de fatura'){
                document.getElementById('invoiceForm').style.display = 'block'
                document.getElementById('communicationUser') == 'none'
                document.getElementById('communicationUser') == 'none'
                $('#actionToTaken').html(`<option value="Atualização" Selected>Atualização</option>`)
                document.getElementById("actionToTaken").setAttribute("disabled", "disabled");
                occurenceBudget.getOccurenceBydget(occurrenceID);
            }

            if(success[0].to_use_in == 'auto faturação' || status == 'Pagamento da fatura'){
                getBillingState('auto faturação')
            }

            if(status == 'Término de acordo do pagamento'){
                getBillingState('Pago na totalidade')
            }

            if(status == 'Elaboração do orçamento'){
                occurenceBudget.getOccurenceBydget(occurrenceID);
            } else {
                $('#actionWarning').modal('show');
            }
        },

        error: function(xhr){
          //  console.log(xhr);
        }
    })
}

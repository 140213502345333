$( () => {
    if($('#sinisterForm').length > 0){

        drivers = document.getElementById('inputDriver');

        $('#inputVehicle').on('change', (event) => {
            event.preventDefault();

            vehicle = document.getElementById('inputVehicle');
            sinisterDate = document.getElementById('inputSinisterDate');

            $.ajax({
                url: '/fleets/vehicleDriver/vehicleDrivers',
                data: { vehicleID: vehicle.value/* , givenDate: sinisterDate.value  */},
                type: 'GET',
                contentType: 'json',
                success: (data) => {

                    var options = '<option value="">Selecionar</option>';

                    data.length > 1 || data.length < 1 ? data.push({id: '', name: 'Não encontrado' }) : data

                    data.forEach( (item) => {
                        options += '<option value="' + item.id + '">' + item.name + '</option>';
                    })

                    $('#inputUserID').html(options);
                },
                error: (xhr) => {
                    //console.log(xhr);
                }
            })
        })
    }


    if($('#editSinister').length > 0){

        $("#inputSinisterLost").on('change', function(event){

            event.preventDefault()

            var inputID = document.getElementById('inputID').value
            var registration = document.getElementById('inputRegistration').value
            const vehiclesStatus =  document.getElementById('inputSinisterLost').value

            if(vehiclesStatus == 1){
                $.ajax({
                    url: `/fleets/vehicle/getvehicleByID/${inputID}`,
                    type: 'GET',
                    success:function(data){

                        const Okay = data.length > 0 ?  true : null

                        if(Okay){
                            if(data[0].gps_removed == 0 && data[0].gps == 1){

                                var alertcontent = `O Veículo com a matrícula <strong> ${ registration } </strong> tem dispositivo de geolocalização instalado. Tem de ser removido antes do abate</strong>.`
                                $('#contentBody').html(alertcontent)
                                $("#alertGPDModal").modal("show");

                            }
                        }
                    },
                    error: function(xhr){
                        //
                    }
                })
            }
        })
    }
})

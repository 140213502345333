export function saveComment(processID, content){

    if(processID){
        $.ajax({
            url: `/ilicits/process/updatecomment/`,
            type: 'GET',
            data: {processID: processID, content: content},
            contentType: 'json',
            success: (success) => {
                var editor = tinymce.get('commentEditorModal');
                editor.setContent('');
                const table = $('#datatable-auto-process').DataTable();
                table.ajax.reload();
            },
            error: function(xhr){
              //  console.log(xhr);
            }
        })
    }
}


export function updateComment(process){
    $.ajax({
        url: `/ilicits/process/getcomment/`,
        type: 'GET',
        data: { id: process },
        contentType: 'json',
        success: (success) => {

            var editor = tinymce.get('commentEditorModal');
            editor.setContent(success[0].description);

            document.getElementById('updateComment').style.display = 'block'
            document.getElementById('saveComment').style.display = 'none'
            $('#commentModal').modal('show');

            $(document).on( "click", '#updateComment', function( event ) {
                event.preventDefault()
                var conteudo = editor.getContent()
                this.saveComment(process, editor)
               process = '';
            })
        },
        error: function(xhr){
          //  console.log(xhr);
        }
    })
}




export function addComment(process){
    $.ajax({
        url: `/ilicits/process/getcomment/`,
        type: 'GET',
        data: { id: process },
        contentType: 'json',
        success: (success) => {

            document.getElementById('updateComment').style.display = 'none'
            document.getElementById('saveComment').style.display = 'block'
            $('#commentModal').modal('show');

            $(document).on( "click", '#saveComment', function( event ) {
                event.preventDefault()
                var conteudo = editor.getContent()
                processComments.updateComment(process, conteudo)
            })
        },
        error: function(xhr){
          //  console.log(xhr);
        }
    })
}

$( () => {

    if($('#budgetItemForm').length > 0 ){

        let aux = 0;
        $('#addSubItemBudget').on('click', (event) => {
            event.preventDefault()
            aux=aux+1
            const subItemForm = document.getElementById('subitemForm')

            const iconRemove = `<div class="col-md-12 mb-1 mt-2"><span class="float-right text-danger" title="Remover"><i class="fas fa-minus-circle removeSubitem" id="${aux}" style="cursor:pointer"></i></span></div>`
            const formCards = `<div class="card mb-3" id="cardSubitemForm${aux}">${iconRemove + subItemForm.innerHTML}</div>`

            $('#subitemsFormCard').append(formCards)

        })

        document.addEventListener('click', (event) => {
            if(event.target.matches('.removeSubitem')){
                const cardSubitemFormID = event.target.id
                document.getElementById(`cardSubitemForm${cardSubitemFormID}`).remove()
                functions.handleWithTotalValues()
            }
        })
    }

})



$( () => {

    //DESABLE BUTTON TO SUBMIT FORM IN MODAL FOR VALIDATE/BACKTRACKING/CONCLUE PROCESS TO VALIDATE ENTRY DATA
    $('#processActionModal').on('show.bs.modal', function (event) {
        const billingStatus = document.getElementById('billingStatus').style.display
        const communicationUser = document.getElementById('communicationUser').style.display
        const invoiceForm = document.getElementById('invoiceForm').style.display

        if( billingStatus == 'block' || communicationUser == 'block' || invoiceForm == 'block')
        {
            document.getElementById('checkErrors').style.display = 'block'
            document.getElementById('validate').style.display = 'none'
        } else{
            document.getElementById('checkErrors').style.display = 'none'
            document.getElementById('validate').style.display = 'block'
        }
        if(data.action == 'Concluir' || data.action == 'Recuar'){
            document.getElementById('validate').style.display = 'none'
        }
    })




    if(('#icilitprocess').length > 0){

        const formData = new FormData()
        const object = {   stepChaged: '',
                            occurrenceID: '',
                            previousStatus: '',
                            action: '',
                            comment: '',
                            inputFiles: [],
                            inputPictures: [],
                            inputFormCommunication: '',
                            inputCommunicationReference: '',
                            inputCommunicationPhone: '',
                            inputCommunicationDate: '',
                            inputCommunicationLocal: '',
                            invoiceNumber: '',
                            invoiceValue: '',
                            invoiceDate: '',
                            settlementDate: '',
                            amountFractioned: '',
                            paymentDate: '',
                            paymentDescription: '',
                            paymentOption: ''
                        }


        function HandleProcessComment(processID, content){
            if(processID){
                $.ajax({
                    url: `/ilicits/process/updatecomment/`,
                    type: 'GET',
                    data: {processID: processID, content: content},
                    contentType: 'json',
                    success: (success) => {
                        var editor = tinymce.get('commentEditorModal');
                        editor.setContent('');
                        const table = $('#datatable-auto-process').DataTable();
                        table.ajax.reload();
                    },
                    error: function(xhr){
                      //  console.log(xhr);
                    }
                })
            }
        }


        function HandleProcess(data){

            $.ajax({
                url: "/ilicits/process/store/",
                contentType: 'json',
                type: 'GET',
                data: data,
                success: (success) => {
                    var editor = tinymce.get('commentEditorModal');
                    editor.setContent('');
                    const table = $('#datatable-auto-process').DataTable();
                    table.ajax.reload();

                    const billing = $('#inputBillingStates option:selected').text()

                    //GET AND DISABLE FORMS
                    let invoiceForm =  document.getElementById('invoiceForm').style.display
                    let communicationUser = document.getElementById('communicationUser').style.display
                    communicationUser == 'block' ? communicationUser = 'none' : ''

                    if(data.action == 'Concluir' || billing == 'Fatura liquidada' || data.invoiceNumber != '' || billing == 'Pago por fracção em acordo'){
                        invoiceForm == 'block' ? communicationUser = 'none' : ''
                        location.reload();
                    }

                },
                    error: function(xhr){
                  //  console.log(xhr);
                }
            })
        }



        const getBillingState = async (param) => {
            $.ajax({
                url: "/ilicits/process/billingstates/",
                contentType: 'json',
                type: 'GET',
                data: {param: param},
                success: (success) => {
                    let option = `<option value="" selected>Selecional</option>`
                    success.forEach(item => {
                            option += `<option value="${item.id}">${item.occurrence_status_designation}</option>`
                        });

                    $('#inputBillingStates').html(option)
                       document.getElementById('billingStatus').style.display = 'block'
                },
                    error: function(xhr){
                  //  console.log(xhr);
                }
            })
        }


        //Validate Processo
        $('#icilitprocess').on('click', '.currentstep', (event) => {
            event.preventDefault()

            const currentstep = document.querySelector('.currentstep').id
            const occurrenceID = document.getElementById('occurrenceID').value


            document.getElementById('validateProcessTitle').style.display = 'block'
            document.getElementById('validateWarning').style.display = 'block'
            document.getElementById('backtrackProcessTitle').style.display = 'none'
            document.getElementById('backtrackWarning').style.display = 'none'
            document.getElementById('concludeProcessTitle').style.display = 'none'
            document.getElementById('concludeWarning').style.display = 'none'

            document.getElementById('btnBacktrack').style.display = 'none'
            document.getElementById('btnValidate').style.display = 'block'
            document.getElementById('btnCancel').style.display = 'none'
            document.getElementById('btnConclude').style.display = 'none'


            if(document.getElementById("actionToTaken").disabled){
                document.getElementById("actionToTaken").removeAttribute("disabled");
                const actionsToTaken = ['Aprovar', 'Atualizar', 'Validar'];
                let option = `<option value="" Selected>Selecionar</option>`
                actionsToTaken.forEach( item => {
                    option += `<option value="${item}">${item}</option>`
                })

                $('#actionToTaken').html(option)
            }


            $.ajax({
                url: `/ilicits/process/status/${currentstep}`,
                type: 'GET',
                contentType: 'json',
                success: (success) => {
                    const status = success[0].occurrence_status_designation;

                    if(status == 'Comunicação ao utilizador'){
                        document.getElementById('communicationUser').style.display = 'block'
                        document.getElementById('invoiceForm').style.display = 'none'
                    }

                    if(status == 'Aguardar emissão de fatura'){
                        document.getElementById('invoiceForm').style.display = 'block'
                        document.getElementById('communicationUser') == 'none'
                    }

                    if(success[0].to_use_in == 'auto faturação' || status == 'Aguardar pagamento da fatura'){
                        getBillingState('auto faturação')
                    }

                    if(status == 'A aguardar término de acordo do pagamento'){
                        getBillingState('Fatura liquidada')
                    }
                },

                error: function(xhr){
                  //  console.log(xhr);
                }
            })

            const nextstep = Number(currentstep) + 1
            formData.append('stepChaged', nextstep)
            formData.append('occurrenceID', occurrenceID)
            formData.append('previousStatus', currentstep)

            data = object;
            data.stepChaged = nextstep
            data.occurrenceID = occurrenceID
            data.previousStatus = currentstep


            $('#actionWarning').modal('show');
        })






        //Conclude Processo
        $('#icilitprocess').on('click', '.conclude', (event) => {
            event.preventDefault()

            const currentstep = document.querySelector('.conclude').id
            const occurrenceID = document.getElementById('occurrenceID').value

            document.getElementById('validateProcessTitle').style.display = 'none'
            document.getElementById('validateWarning').style.display = 'none'
            document.getElementById('backtrackProcessTitle').style.display = 'none'
            document.getElementById('backtrackWarning').style.display = 'none'

            document.getElementById('concludeProcessTitle').style.display = 'block'
            document.getElementById('concludeWarning').style.display = 'block'

            document.getElementById('btnBacktrack').style.display = 'none'
            document.getElementById('btnValidate').style.display = 'none'
            document.getElementById('btnCancel').style.display = 'none'
            document.getElementById('btnConclude').style.display = 'block'

            data = object;
            data.occurrenceID = occurrenceID
            data.previousStatus = currentstep
            data.action = 'Concluir'

            $.ajax({
                url: `/ilicits/process/getStatusByName/`,
                type: 'GET',
                data: {designation: 'Concluído'},
                contentType: 'json',
                success: (success) => {
                    $('#actionToTaken').html(`<option value="Concluir" Selected>Concluir</option>`)
                    document.getElementById("actionToTaken").setAttribute("disabled", "disabled");
                    data.stepChaged = success[0].id;
                },
                error: function(xhr){
                  //  console.log(xhr);
                }
            })

            $('#actionWarning').modal('show');
        })




        //Backtrack Process
        $('#icilitprocess').on('click', '.backtrack', (event) => {
            event.preventDefault()

            const currentstep = document.querySelector('.backtrack').id
            const occurrenceID = document.getElementById('occurrenceID').value

            document.getElementById('validateProcessTitle').style.display = 'none'
            document.getElementById('validateWarning').style.display = 'none'
            document.getElementById('backtrackProcessTitle').style.display = 'block'
            document.getElementById('backtrackWarning').style.display = 'block'
            document.getElementById('concludeProcessTitle').style.display = 'none'
            document.getElementById('concludeWarning').style.display = 'none'

            document.getElementById('btnBacktrack').style.display = 'block'
            document.getElementById('btnValidate').style.display = 'none'
            document.getElementById('btnCancel').style.display = 'none'
            document.getElementById('btnConclude').style.display = 'none'

            const previousStep = Number(currentstep) - 1
            formData.append('stepChaged', previousStep)
            formData.append('occurrenceID', occurrenceID)
            formData.append('previousStatus', currentstep)

            data = object;
            data.action = 'Recuar'
            data.stepChaged = previousStep
            data.occurrenceID = occurrenceID
            data.previousStatus = currentstep
            $('#actionToTaken').html(`<option value="Recuar" Selected>Recuar</option>`)
            document.getElementById("actionToTaken").setAttribute("disabled", "disabled");

            $('#actionWarning').modal('show');
        })


        //Show Process Modal
        $('#btnBacktrack').on('click', (event) => {
            event.preventDefault()
            document.getElementById('validateModalTitle').style.display = 'none'
            document.getElementById('concludeModalTitle').style.display = 'none'
            document.getElementById('backtrackModalTitle').style.display = 'block'

            document.getElementById('validate').style.display = 'none'
            document.getElementById('conclude').style.display = 'none'
            document.getElementById('backtrack').style.display = 'block'

            $('#processActionModal').modal('show');
        })


        $('#btnValidate').on('click', (event) => {
            event.preventDefault()
            document.getElementById('validateModalTitle').style.display = ''
            document.getElementById('concludeModalTitle').style.display = 'none'
            document.getElementById('backtrackModalTitle').style.display = 'none'

            document.getElementById('checkErrors').style.display = 'none'
            document.getElementById('validate').style.display = 'block'
            document.getElementById('conclude').style.display = 'none'
            document.getElementById('backtrack').style.display = 'none'

            $('#processActionModal').modal('show');
        })


        $('#btnConclude').on('click', (event) => {
            event.preventDefault()
            document.getElementById('validateModalTitle').style.display = 'none'
            document.getElementById('concludeModalTitle').style.display = 'block'
            document.getElementById('backtrackModalTitle').style.display = 'none'

            document.getElementById('validate').style.display = 'none'
            document.getElementById('conclude').style.display = 'block'
            document.getElementById('backtrack').style.display = 'none'

            $('#processActionModal').modal('show');
        })



        $(document).on("click", '#backtrack, #conclude, #validate, #checkErrors', function(event) {

            event.preventDefault()
            const editor = tinymce.get('comment');

            const actionToTaken = document.getElementById('actionToTaken').value;

            const formCommunication = document.getElementById('inputFormCommunication').value
            const communicationReference = document.getElementById('inputCommunicationReference').value
            const communicationLocal = document.getElementById('inputCommunicationLocal').value
            const communicationDate = document.getElementById('inputCommunicationDate').value
            const communicationPhone = document.getElementById('inputCommunicationPhone').value

            data.action = data.action == '' ? actionToTaken : data.action
            data.comment =  editor.getContent()

            formData.append('action', actionToTaken)
            formData.append('comment', editor.getContent() )

            const communicationUser = document.getElementById('communicationUser').style.display
            const billingStatus = document.getElementById('billingStatus').style.display

            if(communicationUser == 'block'){

                formData.append('inputFormCommunication', formCommunication)
                formData.append('inputCommunicationReference', communicationReference)
                formData.append('inputCommunicationDate', communicationDate)
                formData.append('inputCommunicationLocal', communicationLocal)

                data.inputFormCommunication = formCommunication
                data.inputCommunicationReference = communicationReference
                data.inputCommunicationDate = communicationDate
                data.inputCommunicationLocal = communicationLocal
                data.inputCommunicationPhone = communicationPhone


                if(formCommunication  == ''){
                    const formCommunication = document.getElementById('inputFormCommunication')
                    const formErrorMessage = document.getElementById('formErrorMessage')
                    formCommunication.value == '' ? formErrorMessage.innerHTML = 'Este campo é obrigatório' : formErrorMessage.innerHTML = ''
                }
            }



            if(billingStatus == 'block'){

                if(formCommunication  == ''){
                    const formCommunication = document.getElementById('inputFormCommunication')
                    const formErrorMessage = document.getElementById('formErrorMessage')
                    formCommunication.value == '' ? formErrorMessage.innerHTML = 'Este campo é obrigatório' : formErrorMessage.innerHTML = ''
                }
            }


   /*          if(document.getElementById('invoiceForm').style.display == 'block'){
                data.invoiceNumber = document.getElementById('inputInvoiceNumber').value
                data.invoiceValue = document.getElementById('inputInvoiceValue').value
                data.invoiceDate = document.getElementById('inputInvoiceDate').value
            } */


            if(document.getElementById('invoicepayments').style.display == 'block'){
                data.amountFractioned = document.getElementById('inputAmountFractioned').value
                data.paymentDate = document.getElementById('inputPaymentDate').value
                data.paymentDescription = document.getElementById('inputPaymentDescription').value
            }


            if(document.getElementById('invoiceForm').style.display == 'block'){

                const invoiceNumber = document.getElementById('inputInvoiceNumber').value
                const invoiceValue = document.getElementById('inputInvoiceValue').value
                const invoiceDate = document.getElementById('inputInvoiceDate').value

                var invoiceNumberErrorMessage =  document.getElementById('invoiceNumberErrorMessage');
                var invoiceValueErrorMessage =  document.getElementById('invoiceValueErrorMessage');
                var invoiceDateErrorMessage =  document.getElementById('invoiceDateErrorMessage');

                invoiceNumber == '' ? invoiceNumberErrorMessage.innerHTML = 'Este campo é obrigatório' : invoiceNumberErrorMessage.innerHTML = ''
                invoiceValue == '' ? invoiceValueErrorMessage.innerHTML = 'Este campo é obrigatório' : invoiceValueErrorMessage.innerHTML = ''
                invoiceDate == '' ? invoiceDateErrorMessage.innerHTML = 'Este campo é obrigatório' : invoiceDateErrorMessage.innerHTML = ''

                data.invoiceNumber = invoiceNumber
                data.invoiceValue = invoiceValue
                data.invoiceDate = invoiceDate
/*
                if(invoiceNumber == '' || invoiceValue == '' || invoiceDate == ''){
                    document.getElementById('checkErrors').style.display = 'block'
                    document.getElementById('validate').style.display = 'none'
                } else{
                    document.getElementById('checkErrors').style.display = 'none'
                    document.getElementById('validate').style.display = 'block'
                } */
            }

            HandleProcess(data)
        })


        $(document).on("change", '#inputSettlementDate', function(event) {
            event.preventDefault()
            data.settlementDate = document.getElementById('inputSettlementDate').value;
        })


        //HANDLE WITH USER COMMUNICATION INPUT
 /*        $(document).on("change", '#inputFormCommunication', function(event) {
            event.preventDefault()
            const formCommunication = document.getElementById('inputFormCommunication')
            const formErrorMessage = document.getElementById('formErrorMessage')
            const local =  document.getElementById('communicationLocal')
            const reference = document.getElementById('communicationReference')
            const phoneNumber = document.getElementById('communicationPhone')

            formCommunication.value == 'Verbal presencial' ? local.style.display = 'block' : local.style.display = 'none'
            formCommunication.value == 'Carta' ? reference.style.display = 'block' : reference.style.display = 'none'
            formCommunication.value == 'Verbal por telefone' ? phoneNumber.style.display = 'block' : phoneNumber.style.display = 'none'
            formCommunication.value == '' ? formErrorMessage.innerHTML = 'Este campo é obrigatório' : formErrorMessage.innerHTML = ''

            if(formCommunication == ''){
                document.getElementById('checkErrors').style.display = 'block'
                document.getElementById('validate').style.display = 'none'
            } else{
                document.getElementById('checkErrors').style.display = 'none'
                document.getElementById('validate').style.display = 'block'
            }
        }) */


        //HANDLE WITH BILLING INPUT
/*         $(document).on("change", '#inputBillingStates', function(event) {
            event.preventDefault()
            const billingStatus = document.getElementById('inputBillingStates')
            const billingErrorMessage = document.getElementById('billingErrorMessage')
            const settlementDate = document.getElementById('settlementDate')

            billingStatus.value == '' ? billingErrorMessage.innerHTML = 'Este campo é obrigatório' : billingErrorMessage.innerHTML = ''
            billingStatus.innerHTML == 'Fatura liquidada' ? settlementDate.style = 'block' : settlementDate.style = 'none'
            if(billingStatus == ''){
                document.getElementById('checkErrors').style.display = 'block'
                document.getElementById('validate').style.display = 'none'
            } else{
                document.getElementById('checkErrors').style.display = 'none'
                document.getElementById('validate').style.display = 'block'
            }
        }) */



        //HANDLE WITH INVOICES STATUS
/*         if(document.getElementById('invoiceForm').style.display == 'block'){
            document.getElementById('checkErrors').style.display = 'block'
            document.getElementById('validate').style.display = 'none'
        }else{
            document.getElementById('checkErrors').style.display = 'none'
            document.getElementById('validate').style.display = 'block'
        }
 */



        $('#processActionModal').on('hidden.bs.modal', function (e) {
            e.preventDefault();
            document.getElementById('formErrorMessage').innerHTML =''
            document.getElementById('formErrorMessage').innerHTML = ''
  /*           $('#actionToTaken').html(`<option value="">Selecio</option>`) */

        });


        //Handle with closing Modal
        $(document).on("click", '#closeModalComment, #notExecute, #closeModal', function(event) {
            event.preventDefault();
            var editor = tinymce.get('commentEditorModal');
            editor.setContent('');
            const table = $('#datatable-auto-process').DataTable();
            table.ajax.reload();
        });

        $('#commentModal').on('hidden.bs.modal', function (e) {
            var editor = tinymce.get('commentEditorModal');
            editor.setContent('');
            const table = $('#datatable-auto-process').DataTable();
            table.ajax.reload();
        });






        //Get Comment and Show it in Modal
        $(document).on( "click", '.comment', function( event ) {
            event.preventDefault()
            let process = $( event.target ).closest( "span" ).toggleClass('comment').attr('id');

            $.ajax({
                url: `/ilicits/process/getcomment/`,
                type: 'GET',
                data: { id: process },
                contentType: 'json',
                success: (success) => {

                    var editor = tinymce.get('commentEditorModal');
                    editor.setContent(success[0].description);

                    document.getElementById('updateComment').style.display = 'block'
                    document.getElementById('saveComment').style.display = 'none'
                    $('#commentModal').modal('show');

                    $(document).on( "click", '#updateComment', function( event ) {
                        event.preventDefault()
                        var conteudo = editor.getContent()
                        HandleProcessComment(process, conteudo)
                       process = '';
                    })
                },
                error: function(xhr){
                  //  console.log(xhr);
                }
            })
        });






        //Add comment in a Process
        $(document).on( "click", '.addcomment', function( event ) {
            event.preventDefault()
            let process = $( event.target ).closest( "span" ).toggleClass('addcomment').attr('id');

            var editor = tinymce.get('commentEditorModal');

            $.ajax({
                url: `/ilicits/process/getcomment/`,
                type: 'GET',
                data: { id: process },
                contentType: 'json',
                success: (success) => {

                    document.getElementById('updateComment').style.display = 'none'
                    document.getElementById('saveComment').style.display = 'block'
                    $('#commentModal').modal('show');

                    $(document).on( "click", '#saveComment', function( event ) {
                        event.preventDefault()
                        var conteudo = editor.getContent()
                        HandleProcessComment(process, conteudo)
                    })
                },
                error: function(xhr){
                  //  console.log(xhr);
                }
            })
        })
    }
})





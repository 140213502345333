const { trim } = require("jquery");

$(() => {
    let t = null;
    const card = $('<div>', {
        class: 'selection-card border float-left p-0 mt-2',
    });
    const cardH = $('<div>', {
        class: 'card-header'
    });
    const cardB = $('<div>', {
        class: 'card-body'
    });

    const partialLabel = $('<label for="address-partial-check" class="partial-label mr-3">' + $('#translations .partial-label').text() + '</label>')
    const partialCheck = $('<input>', {
        type: 'checkbox',
        name: 'address-partial',
        id: 'address-partial-check'
    });
    const partialInput = $('<input>', {
        type: 'text',
        name: 'address-partial-text',
        class: 'd-none w-100 partial-input',
        required: false,
    });
    const partialInfo = $('<small class="partial-info form-text text-muted w-100 d-none">' + $('#translations .partial-info').text() + '</small>');
    const adjacentLabel = $('<label for="address-adjacent-check" class="adjacent-label mr-3">' + $('#translations .adjacent-label').text() + '</label>')
    const adjacentCheck = $('<input>', {
        type: 'checkbox',
        name: 'address-adjacent',
        id: 'address-adjacent-check',
    });
    const a = $("<a>", {
        href: '#',
        class: 'remove float-right text-danger',
        id:'auto-complete-delete',
    });
    const i = $('<i>', {
        class: 'fas fa-times',
    });

    a.append(i);

    // Remove an address card
    a.add('.auto-complete-delete').on('click', function () {
        const addressToRemove = $(this).parents('.selection-card').attr('id').split('-').slice(1); // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/slice
        const inputData = JSON.parse(input.val());
        const newInputData = inputData.filter((entry) => {
            return entry.type != addressToRemove[0] && entry['data-resource-id'] != addressToRemove[1];
        });
        input.val(JSON.stringify(newInputData));
        $(this).parents('.selection-card').remove();
        input.trigger('change');
        // If its the last card, delete de UL
    });


    // If partial door number is already checked
    if($('input[name=address-partial]').prop('checked')){
        console.log("checked");
        $('input[name=address-partial]').siblings('.partial-input, .partial-info').removeClass('d-none');
    };

    // Display partial door numbers
    partialCheck.add('input[name=address-partial]').on('change', function () {
        if ($(this).prop('checked')) {
            $(this).siblings('.partial-input, .partial-info').removeClass('d-none');
            const resourceId = $(this).parents('.selection-card').attr('id').split('-').slice(1);
            const inputData = JSON.parse(input.val());
            const partialData = $(this).prop('checked');

            inputData.map((entry) => {
                if (entry['data-resource-id'] == resourceId[1] && entry['data-type'] == resourceId[0]) {
                    entry.partial = partialData;
                }
            });

        } else {
            $(this).siblings('.partial-input, .partial-info').addClass('d-none');
            $('input[name=address-partial-text]').val('');
            console.log('in else');
            const resourceId = $(this).parents('.selection-card').attr('id').split('-').slice(1);
            const inputData = JSON.parse(input.val());
            const partialData = '';

            inputData.map((entry, i) => {
                console.log(entry);
                if (entry['data-resource-id'] == resourceId[1] && entry['data-type'] == resourceId[0]) {
                    entry['partial-text'] = partialData;
                }
            });
            console.log('Input data: ', inputData);
            input.val(JSON.stringify(inputData));
            console.log('Input value: ', input.val())
            input.trigger('change');

        }
    });

    // Partial address text box
    partialInput.add('input[name=address-partial-text]').on('keyup', function () {
        clearTimeout(t);
        t = setTimeout(() => {
            const resourceId = $(this).parents('.selection-card').attr('id').split('-').slice(1);
            const inputData = JSON.parse(input.val());
            const partialData = $(this).val();

            inputData.map((entry, i) => {
                console.log(entry);
                if (entry['data-resource-id'] == resourceId[1] && entry['data-type'] == resourceId[0]) {
                    entry['partial-text'] = partialData;
                }
            });
            console.log('Input data: ', inputData);
            input.val(JSON.stringify(inputData));
            console.log('Input value: ', input.val())
            input.trigger('change');
        }, 1000);
    });

    // Adjacent address check box
    adjacentCheck.add('input[name=address-adjacent]').on('change', function () {
        const resourceId = $(this).parents('.selection-card').attr('id').split('-').slice(1);
        const inputData = JSON.parse(input.val());
        const adjacentData = $(this).prop('checked');

        inputData.map((entry, i) => {
            console.log(entry);
            if (entry['data-resource-id'] == resourceId[1] && entry['data-type'] == resourceId[0]) {
                entry.adjacent = adjacentData;
            }
        });

        input.val(JSON.stringify(inputData));
        input.trigger('change');
    });

    card.append(cardH);

    //Auto complete the search bar and create cards
    const loading = $('#autocomplete-list ul').html();
    const input = $('#autocomplete-list').siblings('input[type=hidden]');

    if ($('div[contenteditable=true].search.autocomplete').length > 0) {
        const multiselect = $('div[contenteditable=true].search.autocomplete').hasClass('multiselect');

        $('body').on('click', function (e) {
            if (!$("#autocomplete-list").hasClass('invisible') && !$(e.target).is('div[contenteditable=true].search.autocomplete')) {
                $("#autocomplete-list").addClass('invisible');
            } else if ($("#autocomplete-list").hasClass('invisible') && $(e.target).is('div[contenteditable=true].search.autocomplete') && $("#autocomplete-list ul").children().length > 1) {
                $("#autocomplete-list").removeClass('invisible');
            }
        });

        $('div[contenteditable=true].search.autocomplete').on("keyup", delay( function() {
            var query = $(this).text().trim();
            var delegation = $('#inputDelegation').val();

            if (window.addressSearchAjax && window.addressSearchAjax.readyState !== 4) {
                window.addressSearchAjax.abort();
            }

            if (query != '') {
                window.addressSearchAjax = $.ajax({
                    url: $(this).attr('data-ajax'),
                    method: "POST",
                    data: {
                        query: query,
                        delegation: delegation
                    },
                    dataType: 'html',
                    success: (data) => {
                        window.addressSearchRequestData = data;

                        // Click no resultado da pesquisa
                        $("#autocomplete-list ul").html(data).find('li a').on('click', function(e) {
                            const allFieldData = JSON.parse(input.val());
                            const data = $(this).attr();
                            data.text = $(this).text().fullTrim();
                            delete data.href;

                            if (!allFieldData.some(item => item['data-resource-id'] === data['data-resource-id'])) {
                                let value = null;
                                if (multiselect) {
                                    allFieldData.push(data)
                                    value = JSON.stringify(allFieldData);
                                } else {
                                    value = data['data-resource-id'];
                                }

                                // Where the hidden input gets the address value
                                input.val(value);
                                input.trigger('change');

                                const id = 'address-' + data["data-type"] + '-' + data["data-resource-id"];
                                const addressCard = card.clone(true).attr({'id': id, 'name': data.text});
                                const addressCardB = cardB.clone(true);
                                const addressRemove = a.clone(true);

                                if (data['data-type'].trim() == 'street') {
                                    console.log();
                                    addressCardB.append(adjacentLabel.clone(true), adjacentCheck.clone(true), partialLabel.clone(true), partialCheck.clone(true), partialInput.clone(true), partialInfo.clone(true)).appendTo(addressCard);
                                    console.log();
                                }

                                addressCard.find('.card-header').append(data.text, addressRemove);

                                if (multiselect) {
                                   
                                    const SingleAddress = document.getElementById('SingleAddress')
 
                                    if(SingleAddress != null){
 
                                        document.getElementById('InputPlaceholderShow').value = data["data-resource-id"];
                                        document.getElementById('inputAddress').innerHTML = data.text;
                                        /* console.log(data.text);
                                        console.log(data) */
                                    }else{
                                        $('#selection-list').append(addressCard);
                                    }
                                }

                                //ADD WARN
                                $.ajax({
                                    url: '/interruptions/getInterruptionByLocation/' + data["data-resource-id"],
                                    type: 'GET',
                                    success: (response) => {
                                        if(response[0]=='-' && id.replace(/[^0-9]/g, '') == data["data-resource-id"]){

                                            const warn = document.createElement('span');
                                            warn.id = 'addressWarning';
                                            warn.title= response[1]
                                            warn.innerHTML = `<i class="fas fa-exclamation-triangle"></i>`;
                                            addressCard.append(warn);

                                        } else console.log();
                                    }
                                });
                            }
                        });

                        $("#autocomplete-list").removeClass('invisible');
                    }
                });
            } else {

                $("#autocomplete-list").addClass('invisible');
            }
        },1000));

        $('div[contenteditable=true].search.autocomplete').on("keyup", function() {
            $("#autocomplete-list ul").html(loading);
            $("#autocomplete-list, #autocomplete-list ul .loading").removeClass('invisible');
        });

        //Give value to hidden input in case there exists addresses already(edit)
        if($('input[name=editAddressHidden]').val() != "" && $('input[name=editAddressHidden]').val() != undefined ){
            console.log($('input[name=editAddressHidden]').val());
            input.val($('input[name=editAddressHidden]').val());
        };
    }
});

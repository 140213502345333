
export function getOccurenceBydget(occurrenceID)
{
    $.ajax({
        url: `/ilicits/occurrenceBudgets/getByOccurrenceId/${occurrenceID.value}`,
        type: 'GET',
        contentType: 'json',
        success: (success) => {
            handleWithOccurrenceBudgetValidation(success)
            provideBudget(success);
        },

        error: function(xhr){
          //  console.log(xhr);
        }
    })
}


export function provideBudget(budget){
    if(budget){
        console.log(budget);

        document.getElementById('occurrenceBudget').style.display = 'block'
        const option = `<option value="${budget.id}" selected>${ budget.budget_code.replace('_', '/') }</option>`
        $('#inputOccurrenceBudget').html(option)

     } else{
        document.getElementById('occurrenceBudget').style.display = 'none'
     }
}

export function handleWithOccurrenceBudgetValidation(corrence){
    if(corrence){
       document.getElementById('budgetAlert').style.display = "none"
       $('#actionWarning').modal('show');
    } else{
        document.getElementById('budgetAlert').style.display = "block"
    }
}




$( () => {

    $("#inputDelegation").on('change', function(event){
        event.preventDefault()

        const inputDelegation = document.getElementById('inputDelegation').value;
        const alertCostCenter = document.getElementById('alertCostCenter')

        const alertNoUsers = document.getElementById('alertNoUsers')
        const alertNoCostCenter = document.getElementById('alertNoCostCenter')
        const alertAllCostCenterAccountable = document.getElementById('alertAllCostCenterAccountable')

        const delegationDesignation =  $('#inputDelegation option:selected').text()

        if($('#costCenterAccountable').length > 0){
            alertCostCenter.style.display == 'block' ? alertCostCenter.style.display = 'none' : ''
            const costCenterAccountable = document.getElementById('inputCostCenters');
            costCenterAccountable.innerHTML = '';

            if(alertNoUsers){
                alertNoUsers.style.display == 'inline-block' ?  alertNoUsers.style.display == 'none' : ''
            }

            if(alertNoCostCenter){
                alertNoCostCenter.style.display == 'inline-block' ?  alertNoCostCenter.style.display == 'none' : ''
            }

            if(alertAllCostCenterAccountable){
                alertAllCostCenterAccountable.style.display == 'inline-block' ?  alertAllCostCenterAccountable.style.display == 'none' : ''
            }
        }

        $.ajax({
            url: `/delegations/costCenters/${inputDelegation}`,
            type: 'GET',
            success:function(data){

                var options = '<option value ""> Selecionar</option>'

                if(data.length > 0){
                    data.forEach( (item) => {
                        options += '<option value="' + item.id + '">' + item.cost_center_designation + '</option>';
                    })
                }else{

                    if($('#costCenterAccountable').length > 0){
                        alertCostCenter.style.display = 'block'
                        alertNoUsers ? alertNoUsers.style.display = 'inline-block' : ''
                        alertNoCostCenter ? alertNoCostCenter.style.display = 'none' : ''


                    } else {

                        alertNoCostCenter ? alertNoCostCenter.style.display = 'inline-block' : ''
                        alertNoUsers ? alertNoUsers.style.display = 'none' : ''
                    }

                    $('#delegationName').html(delegationDesignation)
                }

                $('#inputCostCenter').html(options);
            },
            error: function(xhr){
                //console.log(xhr);
            }
        })
    })
})


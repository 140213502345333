$(()=>{

    const handleWithInputs = (interventionTypeSelected) => {

        //const situation = document.getElementById('interventionSituation')
        const startImobilizationDate = document.getElementById('startImobilizationDate')
        const endImobilizationDate = document.getElementById('endImobilizationDate')
        const interventionDate = document.getElementById('interventionDate')
        const contractSelect = document.getElementById('contractSelect')
        const tireInputs = document.getElementById('tireInputs')
        const nextInterventionDate = document.getElementById('nextInterventionDate')
        const nextInterventionKm = document.getElementById('nextInterventionKm')
        const sinisterSelect = document.getElementById('sinisterSelect');

        const labelIntervationDate = document.getElementById('labelIntervationDate')
        const labelInspectionDate = document.getElementById('labelInspectionDate')
        const labelMaintenanceDate = document.getElementById('labelMaintenanceDate')
        const labelNextMaintenanceDate = document.getElementById('labelNextMaintenanceDate')
        const labelNextInspectionDate = document.getElementById('labelNextInspectionDate')

        if(!(interventionTypeSelected == 'Pneu' && interventionTypeSelected == 'Inspeção')){

            tireInputs ? tireInputs.style.display = 'none' : ''
            nextInterventionDate ? nextInterventionDate.style.display = 'none' : ''
            nextInterventionKm ? nextInterventionKm.style.display = 'none' : ''
        }

        if(interventionTypeSelected == 'Pneu'){

            tireInputs ? tireInputs.style.display = 'block' : ''
            labelIntervationDate ? labelIntervationDate.style.display = 'block' : ''
            interventionDate ? interventionDate.style.display = 'block' : ''

            labelInspectionDate ? labelInspectionDate.style.display = 'none' : ''
            labelNextMaintenanceDate ? labelNextMaintenanceDate.style.display = 'none' : ''
        }

        if(interventionTypeSelected == 'Manutenção Programada'){

            nextInterventionDate ? nextInterventionDate.style.display = 'block' : ''
            nextInterventionKm ? nextInterventionKm.style.display = 'block' : ''
            labelMaintenanceDate ? labelMaintenanceDate.style.display = 'block' : ''
            labelNextMaintenanceDate ? labelNextMaintenanceDate.style.display = 'block' : ''
            interventionDate ? interventionDate.style.display = 'block' : ''

            labelIntervationDate ? labelIntervationDate.style.display = 'none' : ''
            labelInspectionDate ? labelInspectionDate.style.display = 'none' : ''
            labelNextInspectionDate ? labelNextInspectionDate.style.display = 'none' : ''

        } else{
            nextInterventionKm ? nextInterventionKm.style.display = 'none' : ''
            nextInterventionDate ? nextInterventionDate.style.display = 'none' : ''
        }


        if(interventionTypeSelected == 'Inspeção'){

            nextInterventionDate ? nextInterventionDate.style.display = 'block' : ''
            interventionDate ? interventionDate.style.display = 'block' : ''
            labelInspectionDate ? labelInspectionDate.style.display = 'block' : ''
            labelNextInspectionDate ? labelNextInspectionDate.style.display = 'block' : ''

            labelNextMaintenanceDate ? labelNextMaintenanceDate.style.display = 'none' : ''
            labelIntervationDate ? labelIntervationDate.style.display = 'none' : ''
            labelMaintenanceDate ? labelMaintenanceDate.style.display = 'none' : ''
            nextInterventionKm ? nextInterventionKm.style.display = 'none' : ''
            startImobilizationDate ? startImobilizationDate.style.display = 'none' : null
            endImobilizationDate ? endImobilizationDate.style.display = 'none' : null

        }

        if(interventionTypeSelected == 'Recondicionamento'){

            labelIntervationDate ? labelIntervationDate.style.display = 'block' : null
            interventionDate ? interventionDate.style.display = 'block' : null
            labelMaintenanceDate ? labelMaintenanceDate.style.display = 'none' : null
        }

        if( interventionTypeSelected == 'A Definir' ||
            interventionTypeSelected == 'Acerto IUC' ||
            interventionTypeSelected == 'Acerto Kms' ||
            interventionTypeSelected == 'Recondicionamento'
        ){

            nextInterventionKm ? nextInterventionKm.style.display = 'none' : null
            nextInterventionDate ? nextInterventionDate.style.display = 'none' : null
            // ? situation.style.display = 'none' : ''
            startImobilizationDate ? startImobilizationDate.style.display = 'none' : null
            endImobilizationDate ? endImobilizationDate.style.display = 'none' : null
        }

        if( interventionTypeSelected == 'Acerto IUC' || interventionTypeSelected == 'Acerto Kms'){

            contractSelect ? contractSelect.style.display = 'block' : ''
            interventionDate ? interventionDate.style.display = 'none' : null

        } else {
            contractSelect ? contractSelect.style.display = 'none' : ''
        }

        if(interventionTypeSelected == 'Manutenção Corretiva'){

            labelIntervationDate ? labelIntervationDate.style.display = 'block' : null
            interventionDate ? interventionDate.style.display = 'block' : null
            startImobilizationDate ? startImobilizationDate.style.display = 'block' : null
            endImobilizationDate ? endImobilizationDate.style.display = 'block' : null

            labelInspectionDate ? labelInspectionDate.style.display = 'none' : null
            labelMaintenanceDate ? labelMaintenanceDate.style.display = 'none' : null
        }

        if(interventionTypeSelected == 'Sinistro' && sinisterSelect){

            sinisterSelect.style.display = 'block'
            labelIntervationDate ? labelIntervationDate.style.display = 'block' : null
            startImobilizationDate ? startImobilizationDate.style.display = 'block' : null
            endImobilizationDate ? endImobilizationDate.style.display = 'block' : null

            labelMaintenanceDate ? labelMaintenanceDate.style.display = 'none' : null
            interventionDate ? interventionDate.style.display = 'block' : null
            nextInterventionKm ? nextInterventionKm.style.display = 'none' : null
            nextInterventionDate ? nextInterventionDate.style.display = 'none' : null


        } else{
            sinisterSelect ? sinisterSelect.style.display = 'none' : ''
        }

    }


    if(($("#editEnterventionForm").length || $("#interventionForm").length ) > 0){
            const interventionTypeSelected = $('#inputInterventionType option:selected').text()
            handleWithInputs(interventionTypeSelected);

        $('#inputInterventionType').on('change', (event) => {
            event.preventDefault();
            const interventionTypeSelected = $('#inputInterventionType option:selected').text()
            handleWithInputs(interventionTypeSelected);

        })
    }
});
